import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TcFilterDef, TcFilterItem } from '@tc/abstract';
import {
  TcTranslateService,
  closeTcGridDetailsDialog,
  openTcGridDetailsPopup,
} from '@tc/core';
import { DEFAULT_TC_DATA_STATE_KEY, getTcDataFilters } from '@tc/data-store';
import { selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import moment from 'moment';
import { forkJoin, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { getAuthenticatedUser } from '../../../../modules/auth/store/auth.selectors';

import { getTcGridFilters } from '@tc/core';
import { refreshTcData } from '@tc/data-store';

import { PrintService } from '../../../services/print.service';
import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { AssociationCategorie } from '../../associations/types/association-categorie';
import {
  createIndicatorsCategorie1Pdf,
  createIndicatorsCategorie2Pdf,
  getIndicatorsTicadiPDF,
} from '../helpers/indicators-pdf-helpers';
import { IndicatorsFilterParams } from '../interfaces/indicators-filter-params.interface';
import { IndicatorsService } from '../services/indicators.service';
import { IndicatorType } from '../typings/indicator-type.enum';
import { StatusValidation } from '../typings/status-validation.enum';
import {
  exportIndicateursCsv,
  exportIndicatorsAsPdf,
  exportIndicatorsDetailCsv,
  getIndicateursEtatDetails,
  getIndicateursEtatDetailsSuccess,
  indicatorsGridActionButtonClicked,
  loadIndicatorAssociationDetails,
  loadIndicatorAssociationDetailsSuccess,
  saveIndicatorsDetail,
  saveTicadiIndicatorDetails,
  setInitialValuesVisible,
  validateIndicatorsDetail,
  validateTicadiIndicatorsDetail,
} from './indicators.actions';
import { GetIndicateursEtatDetailsSuccessPayload } from './indicators.payloads';
import {
  getIndicatorToUpdate,
  getTicadiIndicatorDetailsToUpdate,
  getTicadiIndicatorToUpdate,
} from './indicators.selectors';

@Injectable()
export class IndicatorsEffects {
  storeKey = 'indicators-grid';

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly printService: PrintService,
    private readonly indicatorsService: IndicatorsService,
    private readonly translateService: TcTranslateService
  ) {}

  handleRowDataAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(indicatorsGridActionButtonClicked),
        tap(({ rowData }) => {
          this.store$.dispatch(
            openTcGridDetailsPopup({
              detailsPopupComponent: 'IndicatorsDetailComponent',
              data: rowData,
              storeKey: this.storeKey,
              maxWidth: '82vw',
            })
          );
        })
      ),
    { dispatch: false }
  );

  exportIndicatorsAsPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportIndicatorsAsPdf),
        switchMap(({ payload }) => {
          const { type } = payload;
          if (type === IndicatorType.BA) {
            const baIndicators = this.indicatorsService.getYearIndicatorById(
              payload.associationId,
              payload.annee,
              payload.trimestreIndicateurs
            );
            return forkJoin([baIndicators, of(payload)]);
          }
          const ticadiIndicators =
            this.indicatorsService.getYearIndicateursByIdForTicadi(
              payload.associationId,
              payload.annee,
              payload.trimestreIndicateurs
            );
          return forkJoin([ticadiIndicators, of(payload)]);
        }),
        tap(([yearIndicators, payload]) => {
          const {
            associationNom,
            associationCode,
            associationId,
            periodeString,
            categorieInt,
            type,
          } = payload;

          if (type === IndicatorType.BA) {
            const filename = `${this.translateService.instant(
              'indicators-etat.pdfExport.filename'
            )} - categ ${categorieInt} - asso ${associationId} - ${periodeString}`;
            if (categorieInt === 1) {
              const indicateursEtatPDfContent = createIndicatorsCategorie1Pdf(
                associationNom,
                associationCode,
                yearIndicators.data,
                this.translateService
              );
              this.printService.buildPdf(indicateursEtatPDfContent, filename);
            } else if (categorieInt === 2) {
              const indicateursEtatPDfContent = createIndicatorsCategorie2Pdf(
                associationNom,
                associationCode,
                yearIndicators.data,
                this.translateService
              );
              this.printService.buildPdf(indicateursEtatPDfContent, filename);
            }
          } else {
            const filename = `AMS_ExportIndicateursEtat_${moment(
              new Date()
            ).format('DDMMYYYY_HHmmss')}`;
            // If version number is not populated and validation status is "not received", export PDF for versionless indicators
            const indicateursEtatPDfContent = getIndicatorsTicadiPDF(
              associationNom,
              associationCode,
              periodeString,
              yearIndicators.data,
              this.translateService
            );
            this.printService.buildPdf(indicateursEtatPDfContent, filename);
          }
        })
      ),
    { dispatch: false }
  );

  getIndicateursEtatDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getIndicateursEtatDetails),
        tap(async ({ payload }) => {
          const { associationId, year, trimestre } = payload;

          const response =
            await this.indicatorsService.getIndicateursEtatDetails(
              associationId,
              year,
              trimestre
            );
          const stateIndicators: GetIndicateursEtatDetailsSuccessPayload = {
            associationId,
            year,
            stateIndicators: response.data,
          };

          this.store$.dispatch(
            getIndicateursEtatDetailsSuccess({ payload: stateIndicators })
          );
        })
      ),
    { dispatch: false }
  );

  exportIndicatorsDetailCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportIndicatorsDetailCsv),
        tap(async ({ payload }) => {
          const {
            associationId,
            associationCode,
            categorieInt,
            annee,
            trimestreIndicateurs,
          } = payload;

          await this.indicatorsService.exportIndicatorsDetails(
            associationId,
            associationCode,
            categorieInt,
            annee,
            trimestreIndicateurs
          );
        })
      ),
    { dispatch: false }
  );

  exportIndicateursCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportIndicateursCsv),
        tap(async () => {
          const indicatorsFilter = await this.getIndicatorsFilter();
          const { filters } = indicatorsFilter;

          const { categorie, equipeeDeLogiciel, active } =
            this.generateIndicatorsParams(filters);

          const curUser = await this.store$
            .select(getAuthenticatedUser)
            .pipe(take(1))
            .toPromise();
          const baId = curUser ? (curUser as any)?.bankId : 0;

          await this.indicatorsService.exportIndicators(
            baId,
            null,
            categorie,
            equipeeDeLogiciel,
            active
          );
        })
      ),
    { dispatch: false }
  );

  loadIndicatorAssociationDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadIndicatorAssociationDetails),
        tap(async ({ payload }) => {
          const { indicator } = payload;
          const { associationCode, associationNom } = indicator;

          const associationCodeDetails: AmsStatsInterface[] = [
            {
              label: 'indicators-detail.association.code',
              value: associationCode,
            },
          ];
          const associationNameDetails: AmsStatsInterface[] = [
            {
              label: 'indicators-detail.association.name',
              value: associationNom,
            },
          ];
          this.store$.dispatch(
            loadIndicatorAssociationDetailsSuccess({
              payload: { associationCodeDetails, associationNameDetails },
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveTicadiIndicatorDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveTicadiIndicatorDetails),
        tap(async () => {
          const indicator = await this.store$
            .select(getTicadiIndicatorToUpdate)
            .pipe(take(1))
            .toPromise();

          const clonedIndicateur = { ...indicator };
          clonedIndicateur.statutValidation = StatusValidation.ARevoir;
          clonedIndicateur.dateModification = new Date().toISOString();

          await this.indicatorsService.saveIndicateursEtatDetails(
            clonedIndicateur
          );
          this.store$.dispatch(
            setInitialValuesVisible({ initialValuesVisible: false })
          );
          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: 'indicators-grid-initial-values',
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveIndicatorsDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveIndicatorsDetail),
        tap(async () => {
          const indicateur = await this.store$
            .select(getIndicatorToUpdate)
            .pipe(take(1))
            .toPromise();

          const clonedIndicateur = { ...indicateur };

          clonedIndicateur.statutValidation = StatusValidation.ARevoir;

          await this.indicatorsService.saveIndicateursEtatDetails(
            clonedIndicateur
          );

          const filters = await this.store$
            .select(getTcGridFilters, { storeKey: this.storeKey })
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            refreshTcData({
              storeKey: this.storeKey,
              filter: filters,
            })
          );

          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: this.storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  validateTicadiIndicatorsDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validateTicadiIndicatorsDetail),
        tap(async () => {
          const ticadiIndicators = await this.store$
            .select(getTicadiIndicatorDetailsToUpdate)
            .pipe(take(1))
            .toPromise();

          if (ticadiIndicators.trimestreIndicateur) {
            const clonedIndicateur = {
              ...ticadiIndicators.trimestreIndicateur,
            };

            clonedIndicateur.statutValidation = StatusValidation.Valide;
            clonedIndicateur.dateValidation = moment().toISOString();

            await this.indicatorsService.saveIndicateursEtatDetails(
              clonedIndicateur
            );
          }

          if (ticadiIndicators.anneeIndicateur) {
            const clonedIndicateur = { ...ticadiIndicators.anneeIndicateur };

            clonedIndicateur.statutValidation = StatusValidation.Valide;
            clonedIndicateur.dateValidation = moment().toISOString();

            await this.indicatorsService.saveIndicateursEtatDetails(
              clonedIndicateur
            );
          }

          const filters = await this.store$
            .select(getTcGridFilters, { storeKey: this.storeKey })
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            refreshTcData({
              storeKey: this.storeKey,
              filter: filters,
            })
          );

          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: this.storeKey,
            })
          );
        })
      ),

    { dispatch: false }
  );

  validateIndicatorsDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validateIndicatorsDetail),
        tap(async () => {
          const indicateur = await this.store$
            .select(getIndicatorToUpdate)
            .pipe(take(1))
            .toPromise();

          const clonedIndicateur = { ...indicateur };

          clonedIndicateur.statutValidation = StatusValidation.Valide;
          clonedIndicateur.dateValidation = moment().toISOString();

          await this.indicatorsService.saveIndicateursEtatDetails(
            clonedIndicateur
          );

          const filters = await this.store$
            .select(getTcGridFilters, { storeKey: this.storeKey })
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            refreshTcData({
              storeKey: this.storeKey,
              filter: filters,
            })
          );

          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: this.storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  private async getIndicatorsFilter() {
    const dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    const indicatorsFilter: TcFilterDef = await selectValueByKey(
      getTcDataFilters,
      dataStore$,
      this.storeKey
    );

    return indicatorsFilter;
  }

  private generateIndicatorsParams(
    filters: TcFilterItem[]
  ): IndicatorsFilterParams {
    let categorie: number | null = null;
    const categorieFilter = filters?.filter((f) => f.key === 'categorie');
    if (categorieFilter.length) {
      const categorieFilterValue = categorieFilter[0]?.value;
      if (categorieFilterValue !== AssociationCategorie.TousCategories) {
        categorie = Number(categorieFilterValue);
      }
    }

    let active: boolean | null = null;
    const activeFilter = filters?.filter((f) => f.key === 'active');
    if (activeFilter.length) {
      const activeFilterValue = activeFilter[0]?.value;
      if (activeFilterValue !== '') {
        active = Boolean(activeFilterValue);
      }
    }

    const baId: number | null =
      filters?.filter((f) => f.key === 'baId').length > 0
        ? Number(filters.filter((f) => f.key === 'baId')[0].value)
        : null;
    const year: number | null =
      filters?.filter((f) => f.key === 'year').length > 0
        ? Number(filters.filter((f) => f.key === 'year')[0].value)
        : null;

    return {
      baId,
      categorie,
      year,
      equipeeDeLogiciel: '',
      active,
    };
  }
}
