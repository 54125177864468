import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { downloadFile } from '../../../shared/utils/export.utils';
import { AssociationCategorie } from '../../associations/types/association-categorie';
import { getLatestEndedTrimestreAndYear } from '../helpers/periode-helpers';
import { GetIndicateursEtatDetailsResponse } from '../interfaces/get-indicateurs-etat-details-response.interface';
import { IndicateursEtat } from '../interfaces/indicateurs-etat.interface';
import { Trimestre } from '../typings/trimestre.enum';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsService {
  private basePath: string;

  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigService
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async exportIndicators(
    baId: number | null = null,
    year: number | null = null,
    categorie: number | null = null,
    equipeeDeLogiciel: string | null = null,
    active: boolean | null = null
  ): Promise<void> {
    const url = `${this.basePath}/indicateurs/export-indicateurs`;

    const params = this.createParams(baId, year, categorie, equipeeDeLogiciel, active);

    const response = await this.httpClient
      .get(url, { params, responseType: 'text' })
      .toPromise();

    const fileName = `AMS_ExportIndicateursEtat_${this.getExportFileNameDateFormat()}.csv`;

    downloadFile(fileName, response);
  }

  public async exportIndicatorsDetails(
    associationId: number,
    associationCode: string,
    associationCategorie: number,
    year: number,
    trimestre: Trimestre
  ): Promise<void> {
    const url = `${this.basePath}/indicateurs/export-indicateurs-details`;
    const params = {
      associationId,
      year,
      trimestre
    };

    const response = await this.httpClient.get(url, { params, responseType: 'text' }).toPromise();

    let filename: string | null = null;
    const exportPeriod = getLatestEndedTrimestreAndYear();
    filename = `Détails Indicateurs - categ ${associationCategorie} - asso ${associationCode} - ${exportPeriod.trimestre.toUpperCase()} ${exportPeriod.year}.csv`;
    downloadFile(filename, response);
  }

  public async getIndicateursEtatDetails(
    associationId: number,
    year: number,
    trimestre: string | null = null
  ): Promise<GetIndicateursEtatDetailsResponse> {
    const url = `${this.basePath}/indicateurs/get-indicateurs-etat-details`;
    const params = {
      aId: associationId,
      year,
      ...(trimestre && { trimestre }),
    };

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getYearIndicatorById(
    associationId: number,
    year: number,
    trimestre: string | null
  ): Promise<GetIndicateursEtatDetailsResponse> {
    const url = `${this.basePath}/indicateurs/get-year-indicateurs-by-id`;
    const params = {
      idAssociation: associationId,
      annee: year,
      trimestre: trimestre,
    };

    const response = await this.httpClient.get(url, { params }).toPromise();
    return response;
  }

  public async getYearIndicateursByIdForTicadi(
    associationId: number,
    year: number,
    trimestre: string | null = null
  ): Promise<GetIndicateursEtatDetailsResponse> {
    const url = `${this.basePath}/indicateurs/get-year-indicateurs-by-id-for-ticadi`;
    const params = {
      idAssociation: associationId,
      annee: year,
      ...(trimestre && { trim: trimestre }),
    };

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getIndicateursEtatByBa(
    baId: string,
    year: number,
    trimestre: Trimestre,
    categorie: AssociationCategorie,
    equipeeDeLogiciel: boolean
  ): Promise<GetIndicateursEtatDetailsResponse> {
    const url = `${this.basePath}/indicateurs/get-indicateurs-etat-by-ba`;
    const params = {
      baId,
      year,
      trimestre,
      categorie,
      equipeeDeLogiciel: equipeeDeLogiciel ? 'true' : 'false',
    };

    const response = await this.httpClient.get(url, { params }).toPromise();
    return response;
  }

  public async saveIndicateursEtatDetails(
    indicateursEtat: IndicateursEtat
  ): Promise<any> {
    const url = `${this.basePath}/indicateurs/save-indicateurs-etat`;

    const response = await this.httpClient
      .post(url, indicateursEtat)
      .toPromise();

    return response;
  }

  private getExportFileNameDateFormat() {
    const now = new Date();

    const date = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hour = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const formattedDate = date < 10 ? `0${date}` : date;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedDate}${formattedMonth}${year}_${formattedHour}${formattedMinutes}${formattedSeconds}`;
  }

  private createParams(
    baId: number | null = null,
    year: number | null = null,
    categorie: number | null = null,
    equipeeDeLogiciel: string | null = null,
    active: boolean | null = null
  ) {
    return {
      ...(baId && { baId }),
      ...(year && { year }),
      ...((categorie != null && categorie !== undefined) && { categorie }),
      ...(equipeeDeLogiciel && { equipeeDeLogiciel }),
      ...(active != null && active !== undefined && { active }),
    };
  }
}
